.message {
    text-align: center;
    margin-top: 500px;
}


@media (max-width: 468px) {
    .message {
        margin-top: 300px;                
    }
    .text {
        font-size: 15px;
    }
}
.icon {
    // width: 90px;
    // height: 40px;
    min-width: 120px;
    max-width: 150px;
    background-color: #b8dbed;
    border: 2px solid black;
    border-radius: 15px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

// @media (max-width: 466px) {
//     .icon {
//         font-size: 10px;
//         min-width: 60px;
//         max-width: 150px;
//     }
// }

@media (max-height: 1000px) {
    .icon {
        // width: 90px;
        // height: 40px;
        min-width: 90px;
        max-width: 150px;
        font-size: 15px;
    }
}


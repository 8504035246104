.menu {
    position: fixed;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    z-index: 9999;
    width: 5rem;
    top: -2rem;
    left: 10px;
  }
  .nav-btn{
    position: absolute;
    top: 25px;
  }

  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

.nav-bar {
    // background: #181818;
    // background: #f1faee;
    position: fixed;
    background: #edede9;
    width: 140px;
    height: 100%;
    top:0;
    z-index: 3;
    min-height: 500px;
    transition: transform 0.3s ease;
}

.logo {
    margin-top: 30px;
    display: block;
    padding: 8px 0;
    text-decoration: none !important;
    img {
        display: block;
        margin: 8px auto;
        padding-top: 30px;
        width: 68px;
        height: auto;

        &.sub-logo {
            width: 100px;
        }
    }

    h1 {
        text-align: center;
        color: black;
        margin-top: -20px;
        font-weight: 600;
        font-size: 20px;
    }
}

nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 100%;
    top: 20%;
    width: 100%;

    a {
      font-size: 30px;
      color: black;
      display: block;
      line-height: 152px;
      height: 152px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: black;

        svg {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 15px;
        letter-spacing: 3px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
        }
      }
    }


    a:nth-child(2) {
        &:after  {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &:after  {
            content: 'CONTACT';
        }
    }

    a.project-link {
      &:after  {
          content: 'PROJECT';
      }
  }

    a.active {
    svg {
        color: #ffd700;
    }

    }

    

}
ul {
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    padding:0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
        a {
            padding: 15px 0;
            display: block;
            font-size: 30px;
            line-height: 20px;
        }

        &:hover svg{
            color: #ffd700;
        }
    }
}


@media (max-width: 768px) {
    .primary-navigation {
        position: fixed;
        width: 140px;
        height: 100%;
        background: #edede9;
        z-index: 100;
        transition: transform 0.3s ease;
    }

    .logo {
      -webkit-tap-highlight-color: transparent;
    }
    

    nav {
        --gap: 1px;
        a {
            margin-bottom: -40px;
            outline: none;
            -webkit-tap-highlight-color: transparent;
        }
    }

    .menu {
        width: 4rem;
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
}


@media (max-width: 1500px) and (max-height: 900px) {
  nav {
      --gap: 1px;
      a {
          margin-top: 10px;
          font-size: 25px;
          margin-bottom: -80px;
          outline: none;
          -webkit-tap-highlight-color: transparent;
      }
  }

}


  
.my-app {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: var(--red);
    --accent: var(--white);
    opacity: 0;
    animation: showDelay 3s ease-in-out 1.5s forwards;
  }
  
  .my-app body {
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }
  
  .my-app * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
  
  .my-app h1,
  .my-app h2,
  .my-app h3 {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
  
  .my-app h1 {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
  }
  
  .my-app h2 {
    font-weight: 400;
    margin: 50px 0 10px;
  }
  
  .my-app p {
    margin: 0 0 30px 0;
    font-size: 18px;
  }
  
  .my-app footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(
      rgba(0, 0, 0, 0) 1px,
      var(--background) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
  }
  
  .my-app footer::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent);
    opacity: 0.2;
  }
  
  .my-app footer svg {
    margin-right: 20px;
  }
  
  .my-app footer a {
    text-decoration: none;
    color: var(--accent);
  }
  
  .my-app code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
  }
  
  .my-app ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
  }
  
  .my-app ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  .my-app ::-webkit-scrollbar-corner {
    background: var(--background);
  }
  
  .my-app html {
    scroll-snap-type: y mandatory;
  }
  
  .my-app h1 {
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0;
  }
  
  .my-app h2 {
    display: none;
    margin: 0;
    color: gold;
    left: calc(50% + 130px);
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 1.2;
    position: absolute;
    z-index: 9999;
  }
  
  .my-app section {
    height: 100vh;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    scroll-snap-align: center;
    perspective: 500px;
  }
  
  .my-app section > div {
    border: 10px dashed yellow;
    width: 50%;
    height: 100%;
    position: relative;
    max-height: 90vh;
    margin: 20px;
    background: #b8dbed;
    overflow: auto;
    z-index: 99;
    border-radius: 15px;
  }

  .my-app section > div::-webkit-scrollbar-thumb {
    background-color: #b8dbed;
  }

  .my-app section > div::-webkit-scrollbar {
  width: 10px;
  background-color: #b8dbed;
}
  
  .my-app img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  
  .my-app .progress {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 15px;
    background: black;
    bottom: 50px;
  }

  .footer {
    margin-bottom: 200px;
  }
  

  @keyframes showDelay {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }


@media (max-width: 466px) {
  .my-app section > div {
    margin-top: 120px;
    width: 80%;
  }
}


.logo-container {
   z-index: 0;
   width: 400px;
   height: 409px;
   opacity: 0;
   position: absolute;
   top: -55%;
   right: auto;
   bottom: 0;
   left: 36%;
   margin: auto;
 
   svg {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     transform: rotateZ(10deg) !important;
     fill: none;
   }
 
   .solid-logo {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     opacity: 0;
     transform: rotateZ(10deg);
     z-index: 1;
   }
 }
 
 .svg-container {
   stroke: black;
   stroke-width: 10;
   
 }

 @media (min-width: 467px) and (max-width: 1167px) {
  .logo-container {
    width: 300px;
    height: 309px;
    left: 25%;
    top: -62%;
    padding-top: -100px;
  }

 }
 
 @media (max-width: 466px) {
  .logo-container {
    width: 250px;
    height: 209px;
    left: 15%;
    top: -35%;
    padding-top: -100px;
  }
 }
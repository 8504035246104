.brand {
    right: 0;
    z-index: 99999;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    margin-left: auto;

    a {
        .brand-yuteng {
            margin-top: 3px;
            // position: fixed;
            // top: 10px;
            // right: 12%;
            display: block;
            margin-right: 10px;
            width: 150px;
            height: auto;
            z-index: 9999;
        }
    }

    
    
    
    .about-resume {
        display: block;
        // position: fixed;
        // right: 30px;
        // top: 10px;
        width: 120px;
        height: 30px;    
        background-color: transparent;
        z-index: 9999;
        
        button {
            text-align: center;
            position: absolute;
            color: black;
            text-align: center;
            font-size: 20px;
            // width: 100%;
            // height: 100%;
            border: 3px solid black;
            background-color: transparent;
            border-radius: 5px;
            &:hover {
                color: black;
                background: #ffd700;
            }
            
        }
        
    }
}




@media(min-width: 469px) and (max-width: 668px) {
    .layout-brand {
        left: 37%;
    }
    
}

@media(max-width: 468px) {
    .brand {
        a {
            .brand-yuteng {
                margin-right: 150px;
                width: 150px;
                height: auto;
            }
        }
    }
}
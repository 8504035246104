.about {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 450px;
    animation: fade-in 1s ease-in-out 2s forwards;
    opacity: 0;

    .about-intro-section {
      display: flex;
      // justify-content: flex-end;
      font-family: 'Courier New', Courier, monospace;
      font-weight: bold;
      height: 1000px;
      margin-top: -250px;
      margin-bottom: 200px;
    }
    
}
.timeline-box {
  margin: 10px auto;
  height: 80px;
  width: 300px;
  border: 5px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin-bottom: 50px;
  .about-time-line{
    font-size: 50px;
    color: black;
  }  
}




.about-breakline{
  margin-left: auto ;
  margin-right: auto ;
  margin-top: 220px;
  width: 80%;
  border: 3px solid black;
  margin-bottom: 200px;
}

.about-introduction {
    text-align: start;
    width: 900px;
    height: 300px;
    font-size: 30px;
    // border: 1px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 300px;
    margin-bottom: -250px;
    visibility: hidden;
}

.show-delayed {
  visibility: visible;
  animation: fade-in 1s ease-in-out 0s forwards;
}

.about-footer {
  height: 110px;
}

@media (min-width: 467px) and (max-width: 1167px) {
  .about {
    
    .about-intro-section {
      display: flex;
      flex-direction: column;
      height: 500px;
      margin-top: -210px;
      margin-bottom: 120px;
    }
  }

  .about-breakline{
  margin-left: auto ;
  margin-right: auto ;
  width: 80%;
  border: 3px solid black;
  margin-top: 400px;
  margin-bottom: 120px;
  }

  .about-introduction {
  margin-top: 150px;
  font-size: 20px;
  height: 300px;
  width: 400px;
  }
}



@media (max-width: 466px) {
    .about {
        .about-intro-section {
          display: flex;
          height: 500px;
          margin-top: -250px;
          margin-bottom: 550px;
        }
    }

    .about-breakline{
      margin-left: auto ;
      margin-right: auto ;
      width: 80%;
      border: 3px solid black;
      margin-bottom: 90px;
    }

    .about-introduction {
      margin-top: 150px;
      font-size: 20px;
      height: 300px;
      width: 300px;
  }
}

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}


  
.layout-brand {
    position: fixed;
    top: 10px;
    right: 12%;
    display: block;
    width: 150px;
    height: auto;
    z-index: 9999;
}

@media(min-width: 469px) and (max-width: 668px) {
    .layout-brand {
        left: 37%;
    }
    
}

@media(max-width: 468px) {
    .layout-brand {
        position: absolute;
        left: 34%;
        display: block;
        width: 120px;
        height: auto;
        z-index: 0;
    }
}
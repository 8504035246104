

.page {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.tags {
    color: rgb(0, 0, 0);;
    opacity: 0.6;
    position: absolute;
    bottom: 5%;
    left: 280px;
    font-size: 38px;
    font-family: 'La Belle Aurore';
}

.top-tags {
    bottom: auto;
    top: 55px;
}

.top-body {
    margin-left: 40px;
}
.bottom-tags {
    margin-left: 40px;
}

.bottom-tag-html {
    margin-left: -40px;
}

.container {
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.footer {
    
    width: 100%;
    text-align: center;
}


@media (max-width: 468px) {
    .about-resume {
        position: fixed;
        right: 15px;
        top: 10px;
        width: 70px;
        height: 30px;
        button {
            font-size: 10px;                        
        }
        
    }
    .tags{
        font-size: 18px;
        left: 15%;
        bottom: 1%;
    }

    .top-tags {
        bottom: auto;
        top: 70px;
        left: 10%;
    }

    

}
.each-contributor {   
    margin-top: 10px; 
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
        color: black;
        font-size: 50px;
    }

    h3 {
        font-size: 20px;
        
    }
}

@media (max-height: 1000px){
    .each-contributor {
        svg {
            font-size: 30px;
        }

        h3 {
            font-size: 15px
        }
    }
}

@media (max-width: 466px) {
    .each-contributor {
        svg {
            font-size: 20px;
        }

        h3 {
            font-size: 15px
        }
    }
}


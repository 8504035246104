.project { 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p {
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        font-weight: bold;
    }

    .project-preview {
        width: 100%; /* Set the desired width for the image container */
        height: 100%; /* Allow the height to adjust based on the image aspect ratio */
        border: 2px solid black;
        
    }

    .project-title {
        width: auto;
        letter-spacing: 1px;
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        margin-top: 10px;
        font-size: 30px;
        margin-bottom: 20px;
        // color: #1db954;
        border-radius: 15px;
        padding: 5px;        
    }

    .project-link {
        max-width: 600px;
        min-width: 200px;
        min-height: 200px;
        width: 80%; 
        height: 50%; 
    }

    .project-description {
        background-color: #b8dbed;
        margin-top: 20px;
        width: 80%; 
        min-height: 25%;
        max-height: 60%;
        border-radius: 10px;
        padding: 10px;
        // margin-bottom: 30px;
        // border: 2px solid black;
    }

    .project-tool {
        padding: 10px;
        // margin-top: 20px;
        width: 80%;
        height: 5%;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        .icon_ {
            margin-bottom: 30px;
            margin-right: 130px;
        }

        .project-github {
            margin-left: 30px;
            width: 10px;
            outline: none;
            -webkit-tap-highlight-color: transparent;
            svg {
                font-size: 28px;
                color: #FAF33E;
            }
        }
    }

    .project-contributor {
        // border: 4px solid black;
        width: 80%;
        height: 15%;
        display: flex;
        justify-content: flex-start;
        // margin-left: -60px;
    }

    .spotability {
        background: url("../../../assets/images/Spotibility.png");
        background-size: cover;
        border-radius: 15px;
        
    }

    .kusa {
        background: url("../../../assets/images/Kusa.gif");
        background-size: 220%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 15px;
    }

    .CS180 {
        background: url("../../../assets/images/CS180.png");
        background-size: cover;
        border-radius: 15px;
    }

    .chess {
        background: url("../../../assets/images/Chess.png");
        background-size: cover;
        border-radius: 15px;
    }
}

@media (min-width: 1200px) and (max-width: 1600px) {
    .project { 
        .project-description {
            min-height: 35%;
        }

        .project-link {
            width: 40%;
            height: 30%;
        }
        
    }
    
}

@media (max-width: 1500px) and (max-height: 1000px){
    .project { 

        p {
            font-size: 16px;
        }
        .project-description {
            min-height: 45%;
        }
        
    }
}



@media (min-width: 820px) and (max-width: 1199px) {
    .project {
        .project-title {
            font-size: 15px;
        }

        p {
            font-size: 16px;
        }
        .project-description {
            min-height: 25%;
        }

        .project-link {
            width: 40%;
            height: 20%;
        }

        .project-tool {
            display: flex;
            flex-wrap: wrap;
            height: 200px;
            
            .icon_ {
                margin-top: 10px;
                .toolIcon_ {
                    min-width: 20px;
                    max-width: 50px;
                }
            }
    
            .project-github {
                margin-left: 30px;
                width: 10px;
                svg {
                    font-size: 28px;
                    color: #FAF33E;
                }
            }
        }
    }
    
}


@media (min-width: 467px) and (max-width: 820px) {
    .project {
        .project-title {
            font-size: 15px;
        }

        p {
            font-size: 16px;
        }
        .project-description {
            min-height: 50%;
        }

        .project-link {
            width: 60%;
            height: 30%;
        }

        .project-tool {
            display: flex;
            flex-wrap: wrap;
            height: 200px;
            
            .icon_ {
                margin-top: 10px;
                .toolIcon_ {
                    min-width: 20px;
                    max-width: 50px;
                }
            }
    
            .project-github {
                margin-left: 30px;
                width: 10px;
                margin-top: 10px;
                svg {
                    font-size: 28px;
                    color: #FAF33E;
                }
            }
        }
    }
    
}

@media (max-width: 466px) {
    .project {
        .project-title {
            font-size: 15px;
        }

        p {
            font-size: 12px;
        }
        .project-description {
            min-height: 45%;
        }

        .project-link {
            width: 60%;
            height: 30%;
        }

        .project-tool {
            display: flex;
            flex-wrap: wrap;
            height: 200px;
            
            .icon_ {
                margin-top: 10px;
                .toolIcon_ {
                    min-width: 20px;
                    max-width: 50px;
                }
            }
    
            .project-github {
                margin-left: 30px;
                width: 10px;
                margin-top: 10px;
                svg {
                    font-size: 28px;
                    color: #FAF33E;
                }
            }
        }
    }
    
}




// @media (max-height: )
.home-me {
    width: 450px;
    // position: absolute;
    // bottom: 15%;
    // right: 10%;
    // border: none;
    // border-radius: 10px;
    // animation: fadeIn 8s;
}

@media(min-width: 468px) and (max-width: 1920px)
{
    .home-me {
        width: 300px;
        bottom: 0;
        left: 35%;
    }
}
@media(min-width: 469px) and (max-width: 800px)
{
    .home-me {
        width: 300px;
        bottom: 0;
        left: 30%;
    }
}

@media (max-width: 468px){
    .home-me {
        position: absolute;
        bottom: 20px;
        left: 30%;
        border: none;
        border-radius: 10px;
        animation: fadeIn 3s ease-in forwards;
    }
}






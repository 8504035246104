.home-page {
    display: flex;
    justify-content: space-around;
    align-items: center;
    ;
    .text-zone {
        // position: absolute;
        // left: 15%;
        // top: 50%;
        margin-top: 400px;
        transform: translateY(-50%);
        width: 900px;
        max-height: 90%;
    }
    h1 {
        // color: #fff;
        color: rgb(0, 0, 0);
        font-size: 62px;
        margin:0;
        font-family: 'Coolvetica';
        font-weight: 400;
        letter-spacing: 10px;

        ._14 {
            margin-right: -30px;
        }
        img {
            margin:0;
            margin-top: 10px;
            width: 100px;
            margin-left: 10px;
            opacity: 0;
            height: 100%;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
            margin-bottom: -30px;
            margin-right: -25px;


        }
    }
}


h2 {
    // color: #8d8d8d;
    color:rgb(17, 12, 42);
    margin-top: 20px;
    font-weight: 400;
    font-size: 21px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
}


.flat-button {
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    margin-top: 25px;
    float: left;
    color:rgb(0, 0, 0);;
    animation: fadeIn 1s 2.5s backwards;
    border: 1px solid rgb(0, 0, 0);;
    padding: 10px;
    white-space: nowrap;

    &:hover {
        color: black;
        background: #ffd700;
    }
}

// @media (min-width: 1920) {
//     .home-page {
//         .text-zone {
//             transform: translateY(-50%);
//             width: 900px;
//             max-height: 90%;
//         }
//         h1 {
//             // color: #fff;
//             color: rgb(0, 0, 0);
//             font-size: 62px;

//             img {
//                 margin:0;
//                 margin-top: 10px;
//                 width: 300px;
//                 margin-left: 10px;
//                 opacity: 0;
//                 height: 100%;
//                 animation: rotateIn 1s linear both;
//                 animation-delay: 1.4s;
//                 margin-bottom: -30px;
//                 margin-right: -25px;
    
    
//             }
//         }
//     }
    
    
//     h2 {
//         // color: #8d8d8d;
//         color:rgb(17, 12, 42);
//         margin-top: 20px;
//         font-weight: 400;
//         font-size: 21px;
//         font-family: sans-serif;
//         letter-spacing: 3px;
//         animation: fadeIn 1s 1.8s backwards;
//     }
    
    
//     .flat-button {
//         font-size: 40px;
//         font-weight: 400;
//         letter-spacing: 4px;
//         font-family: sans-serif;
//         text-decoration: none;
//         margin-top: 25px;
//         float: left;
//         color:rgb(0, 0, 0);;
//         animation: fadeIn 1s 2.5s backwards;
//         border: 1px solid rgb(0, 0, 0);;
//         padding: 10px;
//         white-space: nowrap;
    
//         &:hover {
//             color: black;
//             background: #ffd700;
//         }
//     }
// }

// @media(min-width: 468px) and (max-width: 1000px)
// {
//     .home-page {
//         .text-zone {
//             left: 22%;
//             top: 30%;
//             transform: translateY(-50%);
//             width: 490px;
//             max-height: 90%;
//         }
//         h1 {
//             font-size: 32px;
//             font-family: 'Coolvetica';   
//         }
//     }
    
    
//     h2 {
//         // color: #8d8d8d;
//         margin-top: 20px;
//         font-weight: 400;
//         font-size: 21px;
//     }
    
    
//     .flat-button {
//         font-size: 20px;
//         font-weight: 400;
//     }

// }

@media(min-width: 1134px) and (max-width: 1919px)
{
    .home-page {
        .text-zone {
            left: 25%;
            top: 30%;
            width: 550px;
        }
        h1 {
            font-size: 42px;
        }

        img {
            width: 400px;
        }
    }
    
}
@media(min-width: 662px) and (max-width:1133px) {
    .home-page {
        .text-zone {
            width: 250px;
        }
        h1 {
            font-size: 30px;
        }

        img {
            width: 250px;
        }
    }
}



@media (min-width: 467px) and (max-width: 662px){
    .home-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .text-zone {
            width: 390px;
        }

        h1 {
            font-size: 20px;

            &::after {
                display: none;
            }
        }

        .flat-button {
            font-size: 10px;
        }

        img {
            width: 250px;
            margin-top: -100px;
        }
    }
}


@media (max-width:466px) and (max-height: 670px)
{
    .home-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .text-zone {
            width: 210px;
            margin-top: 150px;
            margin-left: 15px;
        }

        h1 {
            font-size: 22px;
    
            img {
                width: 80px;
            }
        }

        h2 {
            font-size: 16px;
        }

        .flat-button {
            font-size: 10px;
            margin-left: 34px;
        }

        img {
            width: 140px;
            bottom: 0;
        }
    }
}


@media (max-width:466px) and (min-height: 670px)
{
    .home-page{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .text-zone {
            width: 210px;
            margin-top: 160px;
            margin-left: 10px;
        }

        h1 {
            font-size: 25px;
            
            img {
                width: 80px;
            }
        }

        h2 {
            font-size: 19px;
        }

        .flat-button {
            font-size: 15px;
            margin-left: 25px;
        }

        img {
            width: 180px;
            left: 27%;
            bottom: 0;
        }
    }
}


.vertical-timeline {
    .vertical-timeline-element-content {
        border: 5px solid black;
        border-radius: 15px;
        box-shadow: 1 1px 1px 0 red;
        font-family: 'Courier New', Courier, monospace;
        .date {
            font-size: 20px !important; 
            color: black !important;
            font-weight: bold !important;
        }
    }
}
.timeline-element-format {
    
    // display: flex;
    // flex-direction: column;
    // justify-content: space-around;
    // height: 100%;
    
    .company {
        display: flex;
        width: 100%;
        align-items: center;
        .company-img {
            border-radius: 10%;
            width: 60px;
            height: 50px;
            
            background-size: cover;
        }

        h1 {
            font-size: 35px;
            margin-left: 15px;
            font-weight: 800;
        }
    }
    .vertical-timeline-element-title {
        margin-top: -10px;
        margin-bottom: 10px;
        font-size: 20px;
        font-family: 'Courier New', Courier, monospace;
        font-weight: 600;
    }


    .vertical-timeline-element-subtitle {
        font-size: 30px;
        font-weight: bold;
    }

    #description {
        border: 2px solid black;
        border-radius: 10px;
        padding-left: 10px;
        font-weight: bold;
    }

    .timeline-element-format-icon {
        height: 30px;
        width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 25px;

        
        .icon_ {
            
            margin-right: 120px; /* Adjust the spacing between the icons */
        }

        .icon:last-child {
            margin-right: 0; /* Remove the margin from the last icon */
        }
    }
}






// .description {
//     font-size: 30px;
// }

.timeline{
    
    #description {
        font-size: 20px;
    }

    .button {
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
    }
}

@media (max-width: 466px) {
    .vertical-timeline {
        .vertical-timeline-element-content {
            border: 5px solid black;
            border-radius: 15px;
            box-shadow: 10px 10px 10px #BEBBBB;
            font-family: 'Courier New', Courier, monospace;
            width: 290px;
            
            .date {
                font-size: 20px !important; 
                color: black !important;
                font-weight: bold !important;
            }
        }
    }
    .timeline-element-format {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;

        .company {
            display: flex;
            width: 100%;
            align-items: center;
            .company-img {
                border-radius: 10%;
                width: 60px;
                height: 50px;
                
                background-size: cover;
            }
    
            h1 {
                font-size: 25px;
                margin-left: 15px;
                font-weight: 800;
            }
        }
        .vertical-timeline-element-title {
            font-size: 14px;
        }

        .vertical-timeline-element-subtitle {
            font-size: 18px;
        }
        
        .timeline-element-format-icon {
            height: 100px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            
            .icon_ {
                
                margin-right: 120px; /* Adjust the spacing between the icons */
            }
    
            .icon:last-child {
                margin-right: 0; /* Remove the margin from the last icon */
            }
        }
    }
    
    #description {
        font-weight: bold;
    }
    
    
    
    
   
}

